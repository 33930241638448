@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@600;700&family=Oswald:wght@500&family=REM:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&family=Rubik:wght@500;600&family=Ubuntu:ital,wght@0,500;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@700&family=Rubik:ital,wght@1,500&display=swap');
html, body{
  min-height:100%;
  width:100%;
  margin:0;
  padding:0;
  overflow-x:hidden;
}

body {
  font-family:'Play';
  font-size:12px;
}

.header{
  text-align:center;
  padding:20px 10%;
  width:calc(100% - 20%);
  background:#ffffff;
  position:fixed;
  top:0;
  z-index:999
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.content{
  width:100%;
  z-index:998;
}


.main{
  background: rgb(0,82,181);
  /* background: linear-gradient(180deg, rgba(0,82,181,1) 0%, rgba(0,34,74,1) 100%); */
  background-image:url('../images/bg.jpg');
  width:calc(100%);
  height:auto;
  color:#ffffff;
}

.parallelogram {
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  margin-left: 19px; /* (tangens(20deg)*100px)/2 */
}

.reverseParallelogram{
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  margin-left: -19px; /* (tangens(20deg)*100px)/2 */
}

.logo{
  max-width:450px;
  width:80%;
  margin-left:auto;
  margin-right:auto;
  display:block;
}

.imgDiv{
  background-image:url('../images/gridLinkBG.jpg');
  position: relative;
  width: 100vw;
  height: 100vh;
  margin:0 auto;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat:none;
 }

